<template>
  <select v-bind="$attrs" :value="value" @input="$emit('input', $event.target.value)" @change="$emit('change', $event)">
    <option value="gbp">GBP &pound;</option>
    <option value="eur">EUR &euro;</option>
    <option value="usd">USD $</option>
  </select>
</template>
<script>
export default {
  name: 'CurrencySelect',
  props: {
    value: {
      type: String,
      default: 'GBP'
    }
  }
};
</script>
