<template>
  <div class="row">
    <div class="col-xl-3 col-lg-4 col-md-5 col-12">
      <h4>Manage</h4>
      <VerticalPills :items="categories" item-key="id">
        <template #item="{ item }">
          <a href="#" class="nav-link" :class="{ active: selectedCategoryId === item.id }" @click.prevent="onSelectCategory(item)">
            <div class="mb-0"><i class="fa fa-fw mr-1 text-primary" :class="`${item.icon}`"></i> {{ item.title }}</div>
          </a>
        </template>
      </VerticalPills>
    </div>
    <div class="col">
      <SectionTitle :icon="selectedCategory.icon">{{ selectedCategory.title }}</SectionTitle>
      <div class="row">
        <div v-if="loading" class="col-xl-5">
          <SpinnerLogo />
        </div>
        <div v-else-if="selectedCategoryId === 'general'" class="col-xl-5">
          <FormGroup
            id="siteName"
            v-model="form.siteName"
            :disabled="disabled"
            :error="error['siteName']"
            label="Site Name"
            type="text"
            @input="$emit('input', form)"
          />

          <FormGroup
            id="siteCode"
            v-model="form.siteCode"
            :disabled="disabled"
            :error="error['siteCode']"
            label="Site Code"
            type="text"
            @input="$emit('input', form)"
          />

          <FormGroup
            id="assetType"
            v-model="form.assetType"
            :error="error['assetType']"
            label="Asset Type"
            type="select2"
            :options="assetTypeOptions"
          />

          <div class="form-group">
            <label for="dm-profile-edit-name">Currency</label>
            <CurrencySelect
              id="currency"
              v-model="form.currency"
              type="text"
              class="form-control"
              name="currency"
              placeholder="Select a Currency..."
            />
          </div>

          <FormGroup
            v-if="entityOptions.length"
            id="entityId"
            v-model="form.entityId"
            :error="error['entityId']"
            :options="entityOptions"
            type="select2"
            :config="{ allowClear: false }"
            label="Entity"
          />
        </div>

        <div v-else-if="selectedCategoryId === 'images'" class="col-xl-10">
          <div class="border-bottom mb-4">
            <button class="btn btn-primary btn-block mb-4 mt-3" @click.prevent="modals.uploadImage = true">
              <i class="fa fa-plus mr-1"></i> Upload new image
            </button>

            <h5 class="mb-1"><i class="fa fa-user text-primary mr-1"></i> Uploaded Images</h5>
            <p class="font-w600 text-muted">Below are images of this asset that have been uploaded by users.</p>

            <div v-for="img in form.images.filter(i => i.type === 'upload')" :key="img._id" class="row pb-5 mb-5">
              <div class="col-xl-6 col-lg-6 mb-lg-0 mb-4">
                <a :href="img.url" target="_blank"><img class="img-fluid AssetForm-image" :src="img.url" /></a>
              </div>
              <div class="col">
                <FormGroup :id="`img-${img._id}`" v-model="img.label" label="Label" type="text" @input="$emit('input', form)" />

                <FormGroup
                  :id="`img-isPrimary-${img._id}`"
                  v-model="img.isPrimary"
                  class="mb-3"
                  description-position="top"
                  description="The primary image is the main image associated with this asset. It'll be used on the Overview tab, reports etc."
                  type="radio"
                  label="Use as primary?"
                  :options="[
                    { label: 'No', value: false },
                    { label: 'Yes', value: true }
                  ]"
                  @input="onSetPrimaryImage(img)"
                />
                <FormGroup
                  :id="`img-isHidden-${img._id}`"
                  v-model="img.isHidden"
                  class="mb-3"
                  description-position="top"
                  description="Some reports display all images associated with an asset. Select 'Yes' to hide this image from those reports."
                  type="radio"
                  label="Hide on reports?"
                  :options="[
                    { label: 'No', value: false },
                    { label: 'Yes', value: true }
                  ]"
                  @input="$emit('input', form)"
                />
                <div class="text-right">
                  <button class="btn btn-sm btn-primary text-nowrap mr-2" @click.prevent="$emit('save', true)">
                    <i class="fa fa-save mr-1"></i> Save
                  </button>

                  <button v-if="img.type === 'upload'" class="btn btn-sm btn-danger text-nowrap" @click.prevent="onClickDeleteImage(img)">
                    <i class="fa fa-trash mr-1"></i> Delete
                  </button>
                </div>
              </div>
            </div>
            <div v-if="form.images.filter(i => i.type === 'upload').length === 0" class="alert alert-warning">No uploaded images.</div>
          </div>

          <div class="row">
            <div class="col-xl-8 col-lg-12 mb-lg-0 mb-4">
              <h5 class="mb-1"><i class="fa fa-street-view text-primary mr-1"></i> Automatically Fetched Images</h5>

              <p class="font-w600 text-muted">
                Below are images of this asset we have found via Google and Bing Maps. These can't be deleted, but you can exclude them from being
                used in reports.
              </p>
            </div>

            <div v-if="id" class="col">
              <div class="bg-lightest p-3 rounded-md">
                <label class="text-gray-dark font-w600 font-size-sm mb-1">Updated the address? Click below to fetch new images.</label>
                <button
                  class="btn btn-info btn-sm btn-block mr-2"
                  :disabled="loadingAction.updateSatelliteImages"
                  @click.prevent="onClickFetchImages"
                >
                  <span v-if="loadingAction.updateSatelliteImages"> <i class="fa fa-spinner fa-spin mr-1"></i> Fetching Images... </span>
                  <span v-else> <i class="fa fa-images mr-2"></i>Re-fetch images</span>
                </button>
              </div>
            </div>
          </div>

          <div v-for="img in form.images.filter(i => i.type !== 'upload')" :key="img._id" class="row pb-5 mb-5 border-bottom">
            <div class="col-xl-6 col-lg-6 mb-lg-0 mb-4">
              <a :href="img.url" target="_blank"><img class="img-fluid AssetForm-image" :src="img.url" /></a>
            </div>
            <div class="col">
              <FormGroup :id="`img-${img._id}`" v-model="img.label" label="Label" type="text" @input="$emit('input', form)" />

              <FormGroup
                :id="`img-isPrimary-${img._id}`"
                v-model="img.isPrimary"
                class="mb-3"
                description-position="top"
                description="The primary image is the main image associated with this asset. It'll be used on the Overview tab, reports etc."
                type="radio"
                label="Use as primary?"
                :options="[
                  { label: 'No', value: false },
                  { label: 'Yes', value: true }
                ]"
                @input="onSetPrimaryImage(img)"
              />
              <FormGroup
                :id="`img-isHidden-${img._id}`"
                v-model="img.isHidden"
                class="mb-5"
                description-position="top"
                description="Some reports display all images associated with an asset. Select 'Yes' to hide this image from those reports."
                type="radio"
                label="Hide on reports?"
                :options="[
                  { label: 'No', value: false },
                  { label: 'Yes', value: true }
                ]"
                @input="$emit('input', form)"
              />
              <div class="d-flex">
                <button class="btn btn-sm btn-primary text-nowrap mr-2" @click.prevent="$emit('save', true)">
                  <i class="fa fa-save mr-1"></i> Save
                </button>
                <button v-if="img.type === 'upload'" class="btn btn-danger text-nowrap" @click.prevent="onClickDeleteImage(img)">
                  <i class="fa fa-trash mr-1"></i> Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="selectedCategoryId === 'portfolios' && id" class="col-xl-10">
          <p class="font-w600 text-muted">Manage the portfolios that this asset is a part of. Changes will be saved automatically.</p>

          <FormGroup
            id="assetGroup"
            v-model="assetGroupForm"
            label="Selected Options"
            type="select-array-list"
            :options="assetGroupOptions"
            :search="true"
            overflow
            @itemAdded="onAddAssetGroup"
            @itemRemoved="onRemoveAssetGroup"
          />
        </div>

        <div v-else-if="selectedCategoryId === 'status'" class="col-xl-5">
          <p class="font-w600 text-muted">The current status and history of this asset.</p>
          <div class="mb-4 font-w600 font-size-h5">
            Status:
            <span class="text-capitalize" :class="{ 'text-success': value.status === 'active', 'text-warning': value.status === 'inactive' }">
              {{ value.status }}</span
            >
          </div>

          <table v-if="sortedStatusHistory.length > 0" class="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Value</th>
                <th>Notes</th>
                <th class="text-right"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in sortedStatusHistory" :key="item._id">
                <td>{{ item.date | date }}</td>
                <td>{{ item.value | capitalize }}</td>
                <td>{{ item.notes || '-' }}</td>
                <td class="text-right">
                  <button class="btn btn-link text-secondary mr-2" @click="() => onClickEditStatus(item)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button class="btn btn-link text-danger" @click="() => onClickDeleteStatus(item)">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else class="alert alert-warning">No status history.</div>
          <button class="btn btn-primary" data-cy="add-status-btn" @click="onClickAddStatus">Add Status</button>
        </div>

        <div v-else-if="selectedCategoryId === 'address'" class="col-xl-6">
          <p class="font-w600 text-muted">Enter the address details of this site.</p>
          <AddressForm v-if="form.address" v-model="form.address" :error="error" />
        </div>

        <div v-else-if="selectedCategoryId === 'building'" class="col-xl-5">
          <p class="font-w600 text-muted">More details about this building, such as tenancy, construction year etc.</p>

          <FormGroup
            id="tenure"
            v-model="form.tenure"
            :error="error['tenure']"
            :options="[
              { value: 'freehold', label: 'Freehold' },
              { value: 'leasehold', label: 'Leasehold' }
            ]"
            type="radio"
            label="Tenure"
          />

          <FormGroup
            id="noOfFloors"
            v-model="form.noOfFloors"
            :error="error['noOfFloors']"
            type="number"
            label="Number of floors"
            placeholder="Enter number of floors..."
            :min="1"
            :max="100"
          />

          <div class="form-group">
            <label for="floorArea">Floor Area</label>
            <div class="input-group">
              <FormItem id="floorArea" v-model="form.floorArea" label="Floor Area" :error="error.floorArea" type="text" class="input-group" />
              <div class="input-group-append">
                <FormItem
                  id="floorAreaUnit"
                  v-model="form.floorAreaUnit"
                  label="Floor Area Unit"
                  :error="error.floorAreaUnit"
                  :options="[
                    { value: 'metric', label: 'm2' },
                    { value: 'imperial', label: 'sq. ft' }
                  ]"
                  type="select"
                  :is-alt="true"
                />
              </div>
              <FormError id="floorArea" :error="error.floorArea" />
            </div>
          </div>

          <FormGroup
            id="occupationStatus"
            v-model="form.occupationStatus"
            :error="error['occupationStatus']"
            :options="[
              { value: 'occupied', label: 'Occupied' },
              { value: 'vacant', label: 'Vacant' },
              { value: 'other', label: 'Other' }
            ]"
            type="select"
            label="Building Status"
          />

          <FormGroup
            id="type"
            v-model="form.type"
            :error="error['type']"
            :options="[
              { value: 'standing investment', label: 'Standing Investment' },
              { value: 'new construction', label: 'New Construction' },
              { value: 'major renovation', label: 'Major Renovation' }
            ]"
            type="select"
            label="Building Type"
          />
          <FormGroup id="vacancyRate" v-model="form.vacancyRate" :error="error['vacancyRate']" label="Vacancy Rate" type="text" />
          <FormGroup
            id="constructionYear"
            v-model="form.constructionYear"
            :error="error['constructionYear']"
            label="Construction Year"
            type="datePicker"
            date-format="yyyy"
            date-min-view-mode="years"
            placeholder="Select a date..."
          />
          <FormGroup
            id="ownedSince"
            v-model="form.ownedSince"
            :error="error['ownedSince']"
            label="Owned Since"
            type="datePicker"
            placeholder="Select a date..."
          />
          <FormGroup
            id="disposalDate"
            v-model="form.disposalDate"
            :error="error['disposalDate']"
            label="Disposal Date"
            type="datePicker"
            placeholder="Select a date..."
          />
          <div class="form-group">
            <label for="tenantControlledPercent">Tenant Controlled</label>
            <RangeSlider v-model="form.tenantControlledPercent" postfix="%" @onSlide="onTenantSlide" />
          </div>
          <div class="form-group">
            <label for="landlordControlledPercent">Landlord Controlled</label>
            <RangeSlider v-model="form.landlordControlledPercent" postfix="%" @onSlide="onLandlordSlide" />
          </div>

          <FormGroup id="occupancyLevel" v-model="form.occupancyLevel" :error="error['occupancyLevel']" label="Occupancy Level" type="text" />

          <div class="form-group">
            <label for="dm-profile-edit-name">Gross Asset Value</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <CurrencySelect
                  id="gavCurrency"
                  v-model="form.gavCurrency"
                  type="text"
                  class="form-control form-control-alt"
                  name="gavCurrency"
                  placeholder="Enter a Gross Asset Value Currency..."
                />
              </div>
              <input id="gav" v-model="form.gav" type="text" class="form-control" name="gav" placeholder="Enter a Gross Asset Value..." />
            </div>
          </div>

          <FormGroup id="gresbId" v-model="form.gresbId" :error="error['gresbId']" type="text" label="GRESB ID" />
        </div>

        <div v-else-if="selectedCategoryId === 'benchmarks'" class="col-xl-6">
          <p class="font-w600 text-muted">All settings related to benchmarks such as REEB, LETI and CRREM.</p>

          <div v-for="benchmark in benchmarks" :key="benchmark._id" class="mb-5">
            <h4 class="mb-2">{{ benchmark.name }}</h4>
            <p class="mb-3">{{ benchmark.description }}</p>
            <FormGroup
              v-for="field in benchmark.fields"
              :id="`${benchmark._id}-${field.code}`"
              :key="field.code"
              :label="field.name"
              :type="field.formType"
              :options="field.data.map(c => ({ value: c, label: c }))"
              :value="form.benchmarks?.find(b => b.benchmarkId === benchmark._id && field.code === b.fieldCode)?.fieldValue"
              @input="val => onSelectBenchmark(benchmark._id, field, val)"
            />
          </div>
        </div>

        <div v-else-if="selectedCategoryId === 'integrations'" class="col-xl-5">
          <p class="font-w600 text-muted">Third-party integration settings for this asset.</p>
          <FormGroup id="gresbId" v-model="form.gresbId" :error="error['gresbId']" type="text" label="GRESB ID" />
        </div>

        <div v-else class="col-xl-5">
          <p class="font-w600">Select a category from the left.</p>
        </div>
      </div>

      <ConfirmModal :open="!!modals.status" title="Change status" @close="modals.status = false" @submit="onConfirmStatusModal">
        <div v-if="modals.status">
          <FormGroup id="date" v-model="modals.status.date" label="Date" type="datePicker" />
          <FormGroup id="value" v-model="modals.status.value" label="Status" type="select" :options="modals.status.options" />
          <FormGroup id="note" v-model="modals.status.notes" label="Notes" type="textarea" />
        </div>
      </ConfirmModal>
      <ConfirmModal :open="!!modals.deleteStatus" title="Delete status" @close="modals.deleteStatus = false" @submit="onConfirmDeleteStatusModal" />
      <ConfirmModal :open="!!modals.deleteImage" title="Delete image" @close="modals.deleteImage = false" @submit="onDeleteImage">
        <div v-if="modals.deleteImage">
          <p>
            Are you sure you want to delete this image from <strong>{{ form.siteName }}</strong
            >?
          </p>
          <a :href="modals.deleteImage.url" target="_blank"><img class="img-fluid AssetForm-image mb-4" :src="modals.deleteImage.url" /></a>
        </div>
      </ConfirmModal>
      <ConfirmModal :open="!!modals.uploadImage" title="Upload image" @close="onCloseUploadImageModal" @submit="onSubmitImage">
        <div v-if="modals.uploadImage">
          <p class="font-w600 text-muted">
            To add a new image, click the upload box below or simply drag an image onto it. Once added, you'll see it under the
            <span class="text-secondary"><i class="fa fa-user text-primary mr-1"></i> Uploaded Images</span> section.
          </p>

          <FileUpload
            v-model="newImageFile.file"
            :progress="newImageFile.progress"
            :error="newImageFile.error"
            :loading="newImageFile.uploading"
            :file-types="['png', 'jpg', 'jpeg', 'gif', 'svg']"
            class="bg-light mb-4"
            button-only
            @input="onSelectFile"
            @reset="error = null"
          />
          <div v-if="imagePreview" class="mb-4">
            <p class="mb-2 font-w600">Preview</p>
            <img :src="imagePreview" class="img-fluid" />
          </div>
        </div>
      </ConfirmModal>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import ConfirmModal from '../ConfirmModal';
import Form from './Form';
import AddressForm from './AddressForm';
import CurrencySelect from '../CurrencySelect';
import FormGroup from '../FormGroup';
import FormItem from '../FormItem';
import FormError from '../FormError';
import RangeSlider from '../RangeSlider';
import SectionTitle from '../base/SectionTitle';
import SpinnerLogo from '../SpinnerLogo';
import VerticalPills from '../base/VerticalPills';
import FileUpload from '@/components/FileUpload';

const initialImageForm = () => ({
  file: {},
  progress: null,
  error: null,
  uploading: false
});

export default {
  name: 'AssetForm',
  components: {
    AddressForm,
    ConfirmModal,
    CurrencySelect,
    FileUpload,
    FormGroup,
    FormItem,
    FormError,
    RangeSlider,
    SpinnerLogo,
    SectionTitle,
    VerticalPills
  },
  extends: Form,
  data() {
    return {
      loading: false,
      modals: {
        status: false,
        deleteStatus: false,
        deleteImage: false,
        uploadImage: false
      },
      categories: [
        { id: 'general', title: 'General', icon: 'fa-gear' },
        { id: 'images', title: 'Images', icon: 'fa-image' },
        { id: 'status', title: 'Status History', icon: 'fa-timeline' },
        { id: 'address', title: 'Address', icon: 'fa-map-location-dot' },
        ...(this.id ? [{ id: 'portfolios', title: 'Portfolios', icon: 'fa-layer-group' }] : []),
        { id: 'building', title: 'Building', icon: 'fa-building' },
        { id: 'benchmarks', title: 'Benchmarks', icon: 'fa-chart-simple' },
        { id: 'integrations', title: 'Integrations', icon: 'fa-circle-nodes' }
      ],
      selectedCategoryId: 'general',
      newImageFile: initialImageForm(),
      assetGroupForm: []
    };
  },
  computed: {
    ...mapGetters({
      loadingAction: 'asset/loadingAction',
      errorAction: 'asset/errorAction',
      assetTypes: 'util/assetTypes',
      entities: 'entity/listStructure',
      assetGroups: 'assetGroup/assetGroups',
      benchmarks: 'benchmark/benchmarks',
      geocoordinates: 'asset/geocoordinates'
    }),
    assetTypeOptions() {
      return this.assetTypes ? this.assetTypes.map(type => ({ label: type.value, value: type.value })) : [];
    },
    entityOptions() {
      const extractChildren = (entity, level = 1) =>
        entity.children.reduce(
          (acc, entity) => [...acc, { label: entity.legalName, value: entity._id, level }, ...extractChildren(entity, level + 1)],
          []
        );

      return this.entities.reduce((acc, entity) => {
        return [...acc, { label: entity.legalName, value: entity._id, level: 0 }, ...extractChildren(entity)];
      }, []);
    },
    assetGroupOptions() {
      return this.assetGroups.map(ag => ({ label: ag.name, value: ag._id }));
    },
    sortedStatusHistory() {
      const sorted = [...(this.form.statusHistory || [])];

      sorted.sort((a, b) => new Date(b.date) - new Date(a.date));

      return sorted;
    },
    selectedCategory() {
      return this.categories.find(c => c.id === this.selectedCategoryId);
    },
    imagePreview() {
      if (!this.newImageFile.file?.file) return null;

      const file = this.newImageFile.file.file.get('file');

      if (!file) return null;

      return URL.createObjectURL(file);
    }
  },
  async mounted() {
    this.loading = true;

    this.listEntities();
    this.listAssetTypes();
    this.listBenchmarks({ data: { params: { type: 'asset' } } });

    (this.form.images || []).sort((a, b) => b.isPrimary - a.isPrimary);

    if (this.$route.query.category) {
      this.selectedCategoryId = this.$route.query.category;
    }

    await this.listAssetGroups({ data: { params: { type: 'custom' } } });

    this.assetGroupForm = this.assetGroups.filter(a => this.id && a.assetIds.includes(this.id)).map(a => a._id);

    this.loading = false;
  },
  methods: {
    ...mapActions({
      listAssetTypes: 'util/listAssetTypes',
      addStatus: 'asset/addStatusHistory',
      updateStatus: 'asset/updateStatusHistory',
      deleteStatus: 'asset/deleteStatusHistory',
      listAssetGroups: 'assetGroup/list',
      listBenchmarks: 'benchmark/list',
      getImageUploadUrl: 'asset/getImageUploadUrl',
      updateSatelliteImages: 'asset/updateSatelliteImages',
      addAssetToAssetGroups: 'assetGroup/addAsset',
      removeAssetFromAssetGroups: 'assetGroup/removeAsset',
      listEntities: 'entity/listStructure'
    }),
    onSelectCategory(item) {
      this.selectedCategoryId = item.id;

      this.$router.replace({ query: { category: item.id } });
    },
    onTenantSlide(val) {
      this.form.landlordControlledPercent = 100 - val;
    },
    onLandlordSlide(val) {
      this.form.tenantControlledPercent = 100 - val;
    },
    onSelectBenchmark(benchmarkId, field, val) {
      if (!this.form.benchmarks) {
        this.form.benchmarks = [];
      }

      const existingBenchmark = this.form.benchmarks.find(b => b.benchmarkId === benchmarkId && b.fieldCode === field.code);

      if (existingBenchmark) {
        existingBenchmark.fieldCode = field.code;
        existingBenchmark.fieldValue = val;
      } else {
        this.form.benchmarks.push({ benchmarkId, fieldCode: field.code, fieldValue: val });
      }
    },
    onClickAddStatus() {
      const status = this.form.status === 'active' ? 'inactive' : 'active';
      this.modals.status = {
        date: new Date(),
        value: status,
        notes: '',
        mode: 'add',
        options: [{ label: status.charAt(0).toUpperCase() + status.slice(1), value: status }]
      };
    },
    onClickEditStatus(item) {
      this.modals.status = {
        ...item,
        date: item.date,
        mode: 'edit'
      };
    },
    onClickDeleteStatus(item) {
      this.modals.deleteStatus = item;
    },
    async onConfirmDeleteStatusModal() {
      const updatedAsset = await this.deleteStatus({
        id: this.id,
        statusId: this.modals.deleteStatus._id
      });

      this.modals.deleteStatus = false;

      this.resetFormFromAsset(updatedAsset);
    },
    async onConfirmStatusModal() {
      let updatedAsset;

      if (this.modals.status.mode === 'edit') {
        updatedAsset = await this.updateStatus({
          id: this.id,
          statusId: this.modals.status._id,
          data: {
            date: this.modals.status.date,
            value: this.modals.status.value,
            notes: this.modals.status.notes
          }
        });
      } else {
        updatedAsset = await this.addStatus({
          id: this.id,
          data: {
            date: this.modals.status.date,
            value: this.modals.status.value,
            notes: this.modals.status.notes
          }
        });
      }

      this.modals.status = false;
      this.resetFormFromAsset(updatedAsset);
    },
    onSetPrimaryImage(img) {
      // Set all others to false
      if (img.isPrimary) {
        this.form.images = this.form.images.map(i => ({ ...i, isPrimary: i._id === img._id }));
      }

      this.$emit('input', this.form);
    },
    async onSubmitImage() {
      this.newImageFile.uploading = true;

      const file = this.newImageFile.file.file.get('file');

      const urlInfo = await this.getImageUploadUrl({ fileType: file.type });

      try {
        await this.$http.put(urlInfo.s3Url, file, {
          headers: {
            'Content-Type': file.type,
            'x-amz-acl': 'public-read'
          },
          uploadProgress: progressEvent => {
            this.newImageFile.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }
        });

        this.newImageFile.s3Key = urlInfo.s3Key;

        this.form.images.push({
          type: 'upload',
          url: urlInfo.publicUrl,
          isHidden: false,
          isPrimary: false
        });

        this.newImageFile.uploading = false;
        this.modals.uploadImage = false;
      } catch (e) {
        this.error = 'Could not upload file';
        console.log(e);
      }

      this.$emit('save');

      this.uploading = false;
      this.newImageFile = initialImageForm();
    },
    onClickDeleteImage(img) {
      this.modals.deleteImage = img;
    },
    onDeleteImage() {
      this.form.images = this.form.images.filter(i => i._id !== this.modals.deleteImage._id);

      this.modals.deleteImage = false;

      this.$emit('save');
    },
    onCloseUploadImageModal() {
      this.modals.uploadImage = false;
      this.newImageFile = initialImageForm();
    },
    async onClickFetchImages() {
      const response = await this.updateSatelliteImages({ id: this.$route.params.id });

      if (response._id && response.images) {
        this.form.images = response.images;
        this.form.images.sort((a, b) => b.isPrimary - a.isPrimary);
        this.$toasted.success('Satellite and Street View images updated');
      } else {
        this.$toasted.error('Could not fetch images');
      }
    },
    async onAddAssetGroup(assetGroupId) {
      this.addAssetToAssetGroups({ id: assetGroupId, assetId: this.id });
    },
    async onRemoveAssetGroup(assetGroupId) {
      this.removeAssetFromAssetGroups({ id: assetGroupId, assetId: this.id });
    },
    resetFormFromAsset(asset) {
      Object.keys(this.value).forEach(key => {
        this.$set(this.form, key, asset[key]);
      });
    }
  }
};
</script>
<style scoped>
.AssetForm-image {
  object-fit: contain;
  object-position: top;
}
</style>
