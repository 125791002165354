var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('select', _vm._b({
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event.target.value);
      },
      "change": function change($event) {
        return _vm.$emit('change', $event);
      }
    }
  }, 'select', _vm.$attrs, false), [_c('option', {
    attrs: {
      "value": "gbp"
    }
  }, [_vm._v("GBP £")]), _c('option', {
    attrs: {
      "value": "eur"
    }
  }, [_vm._v("EUR €")]), _c('option', {
    attrs: {
      "value": "usd"
    }
  }, [_vm._v("USD $")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }